
import { AuthModule } from "@/store/modules/auth";
import { ElMessage } from "element-plus";
import { ErrorMessage, Field, Form } from "vee-validate";
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";
import * as Yup from "yup";

export default defineComponent({
  name: "password-reset",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const authModule = getModule(AuthModule, store);
    const router = useRouter();

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const forgotPassword = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
    });

    //Form submit function
    const onSubmitForgotPassword = (values) => {
      // eslint-disable-next-line
      submitButton.value!.disabled = true;
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // dummy delay
      setTimeout(() => {
        // Send login request
        authModule
          .forgotPassword(values)
          .then(() => {
            ElMessage.success(
              "Se ha enviado un recordatorio de contraseña a tu correo electrónico."
            );
            router.push({ name: "acceso-familias" });
          })
          .catch(() => {
            ElMessage.error(
              "Se produjo un error. Por favor, inténtelo de nuevo en unos minutos."
            );
          });

        submitButton.value?.removeAttribute("data-kt-indicator");
        // eslint-disable-next-line
        submitButton.value!.disabled = false;
      }, 2000);
    };

    return {
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
    };
  },
});
